/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { RolesEnum } from '@app/shared/enum/role-enum';
import { ChatBotConfigModel } from '@app/shared/models/other/chat-bot-config.model';
import { environment } from '@environments/environment';
interface DfMessengerElement extends HTMLElement {
  setQueryParameters: (params: {
    parameters: { userEmail: string; userRoles: RolesEnum[] };
  }) => void;
}
@Component({
  selector: 'app-chat-bot',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChatBotComponent implements AfterViewInit {
  chatBotConfig: ChatBotConfigModel = environment.chatBotConfig;
  @Input() userEmail = '';
  @Input() userRoles: RolesEnum[] = [];
  @ViewChild('dfMessenger', { static: false })
  dfMessenger!: ElementRef<DfMessengerElement>;

  constructor(private renderer: Renderer2) {
    this.loadChatBot();
  }

  ngAfterViewInit() {
    if (this.dfMessenger && this.dfMessenger.nativeElement) {
      this.renderer.listen(
        this.dfMessenger.nativeElement,
        'df-messenger-loaded',
        () => {
          this.setQueryParameters();
        },
      );
    }
  }

  private setQueryParameters() {
    const queryParameters = {
      parameters: {
        userEmail: this.userEmail,
        userRoles: this.userRoles,
      },
    };

    this.dfMessenger.nativeElement.setQueryParameters(queryParameters);
  }

  loadChatBot() {
    if (typeof document !== 'undefined' && this.chatBotConfig !== null) {
      const script = document.createElement('script');
      script.src = this.chatBotConfig.scriptUrl;
      script.async = true;
      document.body.appendChild(script);

      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = this.chatBotConfig.styleUrl;
      document.body.appendChild(style);
    }
  }
}
