import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpResponse } from '@app/shared/models/custom-http-response.model';
import { environment } from '@environments/environment';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { CONSTANTS } from '../constants/constant';
import { AuthService } from '../services/auth/auth.service';
import { ToastService } from '../services/toast/toast.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastService: ToastService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<CustomHttpResponse<any>>> {
    const httpsUrlPattern = /^https:\/\//;

    const token = this.authService.getAccessToken();
    if (token && !httpsUrlPattern.test(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    if (
      request.headers.get('client-side-request') ||
      httpsUrlPattern.test(request.url)
    ) {
      return next.handle(
        request.clone({
          headers: request.headers.delete('client-side-request'),
        }),
      );
    }
    const modifiedRequest = request.clone({
      url:
        (request.headers.has('skip-api-base-path')
          ? ''
          : environment.apiBasePath) + request.url,
    });

    return next.handle(modifiedRequest).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            this.toastService.error(error.error.message);
            this.authService.signOut();
            break;
          case 400:
            this.toastService.error(error.error.message);
            break;
          case 0:
            this.toastService.error(CONSTANTS.MESSAGES.SOMETHING_WENT_WRONG);
            break;
        }
        return throwError(() => error);
      }),
    );
  }
}
