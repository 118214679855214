export const WEB_ANALYTICS_CONST = {
  DEFAULT_COUNTRY: 'IN',
  WEB_URLS: ['discover-apis'],
};

export const API_LANDSCAPE_MSG_CONST = {
  DELETE_VERSION: 'API Version deleted successfully.',
  SUCCESS_UPLOAD_DOCUMENT:
    'Successfully uploaded the version content documentation',
  SUCCESS_UPLOAD_INFORMATION:
    'Successfully updated the version content information and documentation.',
  SUCCESS_UPDATED_INFORMATION:
    'Successfully updated the version content information.',
  INFORMATION_UPDATED_ERROR_IN_UPLOADING:
    'Version content information updated successfully. But got error in uploading documentation.',
  INFORMATION_UPDATED_ERROR_IN_DELETING_DOCUMENTATION:
    'Version content information updated successfully. But got error in uploading documentation.',
  INFORMATION_UPDATED_ERROR_IN_UPLOADING_PRODUCT:
    'Details updated successfully , Error in uploading documentation. Please try again.',
  ERROR_DOWNLOADING_FILE: 'Getting error in downloading the file.',
};

export const DIGITAL_PRODUCT_MSG_CONST = {
  DELETE_VERSION: 'Digital product deleted successfully.',
};

export const SETTINGS_MSG_CONST = {
  SUCCESFULLY_UPDATED:
    'Marketplace configuration settings updated successfully.',
  GROUP_NAME_DUPLICATE: 'group name is already present.',
  INVALID_PORTAL_CONFIGURATION_FIELDS:
    'Fill all the portal configuration required fields.',
  INVALID_CONFIGURE_API_META_DATA_FIELDS:
    'Fill all the meta data schema and configure API required fields.',
  INVALID_GATEWAY_FREQUENCY_FIELDS:
    'Fill all the gateway type frequency required fields.',
  ERROR_FETCHING_CONFIGURATION: 'Error in fetching settings configuration.',
};

export const VISILIBILITY_MSG_CONT = {
  FAILED_TO_FETCH: 'Failed to fetch visibility data.',
};

export const GATEWAY_CREDENTIALS_MSG_CONST = {
  REVOKING_CREDENTIALS: 'Credentials successfully revoked',
  GENERATE_CREDENTIAL_ERR_MSG: 'Error in generating credentials',
  FETCH_CREDENTIAL_ERR_MSG: 'Error in fetching credentials',
  REVOKE_CREDENTIAL_ERR_MSG: 'Error in revoking credentials',
};

export const COPY_TO_CLIPBOARD_MSG_CONST = {
  COPY_TO_CLIPBOARD_SUCCESS_MSG: 'Content copied to clipboard!',
  COPY_TO_CLIPBOARD_ERROR_MSG: 'Unable to copy to clipboard!',
};

export const SWITCHING_TEXT_MSG_CONST = {
  ARRAY_OF_TEXT: [
    'DAC processing your request',
    'Please wait, DAC is ensuring a thorough process',
    'Appreciate your patience',
  ],
};

export const DISCOVER_API_PORTAL_MSG_CONST = {
  ERROR_FETCHING_RECORD_TRY_AGAIN: 'Error in fetching records. Try again.',
};

export const API_AFFINITY_CACHE_CLEAR_MSG_CONST = {
  API_AFFINITY_SUCCESS_MSG: 'Successfully invalidated API Affinity cache!',
  API_AFFINITY_ERROR_MSG: 'Something went wrong, Please try again!',
  API_AFFINITY_NO_CONTRACT:
    "Can't find API affinity, as the contract is not available",

  API_AFFINITY_EMPTY_RESPONSE:
    'We couldn’t identify any potential duplicated digital content.',
  API_AFFINITY_THRESHOLD_MES:
    'No other APIs are in affinity to selected api within the threshold',
};

export const SIGNUP = {
  ERROR_SIGNING_UP_WITH_DUPLICATE_EMAIL:
    'Email already exists. Please enter a new email.',
};

export const LANDING_PAGE_PREVIEW_CONST = {
  ERROR_DOWNLOADING_FILE: 'Error downloading the landing page file',
};

export const SAVE_GENERATE_AI_DOCUMENTATION_MSG_CONST = {
  SUCCESS_MSG: 'Documentation generated by DAC AI is uploaded successfully!',
  ERROR_MSG: 'Something went wrong, Please try again!',
  ERROR_GENERATING_DOC: 'Error generating the documentation. Please try again!',
};

export const CODE_DOWNLOAD_LANGUAGE = {
  SELECT_AT_LEAST_ONE_LANGUAGE:
    'Please either select a value from the dropdown or enter your own.',
  CHOOSE_LANG_OPTION: 'Choose your own language',
};

export const MINIMUM_THRESHOLD_MSG_CONST = {
  ERROR_MSG: 'Error in Updating Minimum Threshold',
};

export const VERSION_CONTENT_DAPPER = {
  ERROR_MSG: 'Error loading dapper',
};

export const USER_LIST_RESPONSE = {
  ERROR_MSG: 'Failed to fetch roles. Please try reloading the page.',
};

export const UI_CUSTOMISATION_MSG_CONST = {
  ERROR_UPDATING_FAVICON: 'An error occurred while updating the favicon',
};
