import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CONSTANTS } from '@app/core/constants/constant';
import { AnalyticsSettingsModel } from '@app/shared/models/analytics/analytics-settings.model';
import { ActivityInsightModel } from '@app/shared/models/analytics/user-activity.model';
import { CustomHttpResponse } from '@app/shared/models/custom-http-response.model';
import { environment } from '@environments/environment';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  analyticsConfig: AnalyticsSettingsModel | null = null;

  recordUserAnalytics(
    payload: ActivityInsightModel,
  ): Observable<CustomHttpResponse<string>> {
    const url = CONSTANTS.API.ANALYTICS.USER_ACTIVITY;
    return this.http.post<CustomHttpResponse<string>>(url, payload);
  }

  getAnalyticsConfig(): Observable<AnalyticsSettingsModel | null> {
    if (this.analyticsConfig) return of(this.analyticsConfig);
    return this.http
      .get<CustomHttpResponse<AnalyticsSettingsModel>>(
        CONSTANTS.API.ANALYTICS.GET_CONFIG,
      )
      .pipe(
        map((res: CustomHttpResponse<AnalyticsSettingsModel>) => {
          if (res.status_code === 200) {
            this.analyticsConfig = res.data;
          } else {
            this.analyticsConfig = null;
          }
          return this.analyticsConfig;
        }),
        catchError(() => {
          this.analyticsConfig = null;
          return of(this.analyticsConfig);
        }),
      );
  }

  getIframeUrl(path: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `${path}?params={"env":"${this.getEnvParams}"}`,
    );
  }

  getIframeUrlForAPIDoctor(path: string, versionedContentId: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `${path}?params={"env":"${this.getEnvParams}","version_content_id":"${versionedContentId}"}`,
    );
  }

  get getEnvParams() {
    return environment.environmentName === 'Pre-Prod' ||
      environment.environmentName === 'Dev'
      ? 'pre-prod'
      : 'prod';
  }
}
