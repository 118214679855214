import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnChanges {
  ngOnChanges() {
    this.basePath = this.isLoggedIn ? '/home' : '';
  }

  @Input() isLoggedIn = false;
  currentYear = new Date().getFullYear();
  basePath = '';
}
