/*
    Function is to deep clone the object similar to loadash clone deep
*/
export function cloneDeep<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const clone: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    clone[key] = cloneDeep(obj[key]);
  }

  return clone;
}
