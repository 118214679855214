<!-- chat bot -->
<df-messenger
  #dfMessenger
  data-testid="dac-chat-bot"
  *ngIf="chatBotConfig.projectId && userEmail && userRoles"
  [attr.location]="chatBotConfig.location"
  [attr.project-id]="chatBotConfig.projectId"
  [attr.agent-id]="chatBotConfig.agentId"
  [attr.language-code]="chatBotConfig.languageCode"
  [attr.max-query-length]="-1"
  [attr.user-email]="userEmail"
  [attr.user-roles]="userRoles"
  allow-feedback="all"
>
  <df-messenger-chat-bubble [chat-title]="chatBotConfig.title">
  </df-messenger-chat-bubble>
</df-messenger>
