<nav class="navbar navbar-expand-lg navbar-light bg-light nav__bar footer__bar">
  <div class="container d-flex align-items-center m-auto">
    <div class="text__grey--700">
      <a
        data-testid="footer-terms-condition-link"
        [routerLink]="[basePath + '/terms-privacy/terms-and-conditions']"
        class="mr-4 fs__14 text__body"
        >Terms of service</a
      >
      <a
        data-testid="footer-privacy-policy-link"
        [routerLink]="[basePath + '/terms-privacy/privacy-policy']"
        class="fs__14 ms-4 text__body"
        >Privacy Policy</a
      >
      <a
        data-testid="footer-contact-us-link"
        *ngIf="isLoggedIn"
        [routerLink]="[basePath + '/help/contact-us']"
        class="fs__14 ms-4 text__body"
        >Contact Us</a
      >
    </div>
    <div class="text-center ms-4 text__body fs__14 d-flex">
      <div class="ms-2">
        <span data-testid="footer-copyright"
          >@{{ currentYear }} Copyright: Digital APIcraft</span
        >
        <span> Powered By</span>
      </div>
      <div class="ms-2 footer-logo">
        <img src="assets/images/footer-logo.png" alt="footer logo" />
      </div>
    </div>
  </div>
</nav>
