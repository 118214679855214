import { Route } from '@angular/router';
import {
  canActivate,
  canActivateIfUnauthenticated,
} from '@app/core/guards/can-activate-guard';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@app/modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [canActivateIfUnauthenticated],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@app/modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [canActivate],
  },
  {
    path: '',
    loadChildren: () =>
      import('@app/modules/public/public.module').then((m) => m.PublicModule),
    canActivate: [canActivateIfUnauthenticated],
  },

  {
    path: '**',
    loadComponent: () =>
      import('@app/shared/components/pagenotfound/pagenotfound.component').then(
        (c) => c.PagenotfoundComponent,
      ),
  },
];
