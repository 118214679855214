import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import navList from '@app/core/constants/nav-items';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NavbarModel } from '@app/shared/models/other/navbar.models';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isCollapsed = false as boolean;
  navList = navList as NavbarModel[];
  currentNavList = navList[0] as NavbarModel | null;
  defaultRoute = '';

  constructor(private router: Router) {}

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onResize(event: any) {
    const innerWidth = event ? event?.target?.innerWidth : window?.innerWidth;
    this.isCollapsed = innerWidth > 768 ? false : true;
  }

  ngOnInit() {
    if (typeof window !== 'undefined')
      this.setCurrentNavList(window.location.pathname);

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    this.router.events.subscribe((event: Event | any) => {
      if (
        event instanceof NavigationEnd ||
        event.routerEvent instanceof NavigationEnd
      ) {
        const cEvent =
          event?.routerEvent instanceof NavigationEnd
            ? event.routerEvent
            : event;
        const matchUrl =
          cEvent.url === '/home' ? cEvent.url : cEvent.url.replace('/home', '');
        this.defaultRoute = cEvent.url === '/home' ? '' : '/home/';
        this.setCurrentNavList(matchUrl);
      }
    });

    this.onResize(null);
  }

  setCurrentNavList(matchUrl: string) {
    const elem = this.navList.find((x) => matchUrl.includes(x.path));
    if (elem) {
      this.currentNavList = elem;
    } else {
      this.currentNavList = this.navList[0];
    }
  }
}
