import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'one-service-hub';
  ngOnInit(): void {
    this.loadAnalyticsScript();
  }

  loadAnalyticsScript() {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');
      script.src = environment.analyticsTagUrl;
      script.async = true;
      document.body.appendChild(script);
    }
  }
}
