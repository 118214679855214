import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@app/core/constants/constant';
import { CONSTANTS_OBIE } from '@app/core/constants/discover.constant';
import { CustomHttpResponse } from '@app/shared/models/custom-http-response.model';
import { DigitalContentTypeEnum } from '@app/shared/models/discover-apis/api-rating-request-payload.model';
import {
  OrgSettingsModel,
  PortalSettingsModel,
  PortalTypeEnum,
  affinitySettingsModel,
} from '@app/shared/models/discover-apis/setting-api.model';
import { of, Observable, map, catchError, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  contentTypes!: DigitalContentTypeEnum[];
  orgSettings = null as OrgSettingsModel | null;
  orgSettingsValue = new BehaviorSubject<OrgSettingsModel | null>(null);

  getOrganisationSettings(): Observable<OrgSettingsModel | null> {
    if (this.orgSettings) return of(this.orgSettings);
    return this.http
      .get<CustomHttpResponse<OrgSettingsModel>>(
        CONSTANTS.API.SETTINGS.GET_SETTING_API,
      )
      .pipe(
        map((res: CustomHttpResponse<OrgSettingsModel>) => {
          if (res.status_code === 200) {
            this.orgSettings = res.data;
            this.orgSettingsValue.next(res.data);
          } else {
            this.orgSettings = null;
          }
          return this.orgSettings;
        }),
        catchError(() => {
          this.orgSettings = null;
          return of(this.orgSettings);
        }),
      );
  }

  checkOrganisationId(
    organisationId: string,
  ): Observable<CustomHttpResponse<boolean>> {
    return this.http.get<CustomHttpResponse<boolean>>(
      CONSTANTS.API.SETTINGS.CHECK_ORG_ID + '/' + organisationId,
    );
  }

  postOrganisationSettings(
    payload: OrgSettingsModel,
  ): Observable<CustomHttpResponse<OrgSettingsModel>> {
    const url = CONSTANTS.API.SETTINGS.GET_SETTING_API;
    const formData: FormData = new FormData();
    const formPayload = { ...payload };
    formPayload.portalsSettings.forEach((fields) => {
      if (fields?.customLandingPageFile?.name) {
        formData.append(
          fields.portalType.toLowerCase() + 'Model.landingPage',
          fields.customLandingPageFile,
          fields.customLandingPageFile.name,
        );
        formData.append(
          fields.portalType.toLowerCase() + 'Model.portalType',
          fields.portalType,
        );
        delete fields.customLandingPageFile;
      }
    });

    formData.append('orgSettingsModel', JSON.stringify(formPayload));
    return this.http
      .post<CustomHttpResponse<OrgSettingsModel>>(url, formData)
      .pipe(
        map((res: CustomHttpResponse<OrgSettingsModel>) => {
          if (res.status_code === 201) {
            this.orgSettings = res.data;
            this.orgSettingsValue.next(res.data);
          }
          return res;
        }),
      );
  }

  getToggleValue(portalsSettings: PortalSettingsModel[], type: PortalTypeEnum) {
    const portalValue = portalsSettings.find((x) => x.portalType === type);
    if (portalValue?.portalType) {
      return portalValue.enabled;
    }
    return false;
  }

  getVersion(): Observable<CustomHttpResponse<boolean>> {
    return this.http.get<CustomHttpResponse<boolean>>(
      CONSTANTS.API.SETTINGS.APP_VERSION,
    );
  }

  apiAffinityCacheClean(): Observable<CustomHttpResponse<null>> {
    return this.http.delete<CustomHttpResponse<null>>(
      CONSTANTS_OBIE.API.API_AFFINITY_CACHE_CLEAR,
    );
  }
  putAiAffinityMinThreshold(
    payload: affinitySettingsModel,
  ): Observable<CustomHttpResponse<affinitySettingsModel>> {
    return this.http.put<CustomHttpResponse<affinitySettingsModel>>(
      CONSTANTS_OBIE.API.AI_AFFINITY_SETTINGS_MINIMUM_THRESHOLD_VAL,
      payload,
    );
  }
}
