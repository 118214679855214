import { RolesEnum } from '@app/shared/enum/role-enum';

export default [
  {
    title: 'Home',
    path: 'dashboard',
    items: [
      {
        title: 'Dashboard',
        path: '',
        icon: 'icon-dashboard-2',
      },
    ],
  },
  {
    title: 'Administration',
    path: 'administration',
    restrictedUsers: [
      RolesEnum.USER,
      RolesEnum.ANONYMOUS_USER,
      RolesEnum.EXTERNAL_USER,
      RolesEnum.INTERNAL_USER,
      RolesEnum.OBIE_USER,
      RolesEnum.THIRD_PARTY_ADMIN,
    ],
    items: [
      {
        path: 'manage-gateway',
        title: 'Gateway Instances',
        icon: 'icon-share',
      },
      // {
      //   path: 'manage-gateway-analytics',
      //   title: 'Analytics Instances',
      //   icon: 'icon-donut-chart-1',
      // },
      {
        path: 'manage-user',
        title: 'Manage Users',
        icon: 'icon-team-management-converted',
      },
      {
        path: 'manage-analytics',
        title: 'Manage Analytics Access',
        icon: 'icon-analytics-access',
      },
    ],
  },
  {
    title: 'Manage Digital Content',
    path: 'digital-contents',
    restrictedUsers: [
      RolesEnum.USER,
      RolesEnum.ANONYMOUS_USER,
      RolesEnum.EXTERNAL_USER,
      RolesEnum.INTERNAL_USER,
      RolesEnum.OBIE_USER,
      RolesEnum.THIRD_PARTY_ADMIN,
    ],
    items: [
      {
        path: 'apis',
        title: 'Manage API Estate',
        icon: 'icon-fetch-api',
      },
      {
        path: 'products',
        title: 'Manage Digital Products',
        icon: 'icon-supply-chain',
      },
    ],
  },
  {
    title: 'Analytics',
    path: 'insights',
    items: [
      {
        path: 'api-analytics',
        title: 'API Ops Analytics',
        icon: 'icon-api-analytics',
      },
      {
        path: 'user-analytics',
        title: 'User Analytics',
        icon: 'icon-user-analytics',
      },
      {
        path: 'web-traffic-analytics',
        title: 'Web Traffic Analytics',
        icon: 'icon-web-traffic-analytics',
      },
      {
        path: 'business-analytics',
        title: 'Business Analytics',
        icon: 'icon-api-analytics',
      },
      /** TODO: OSH-866: Removed sandbox-analytics temporarily, need to enable in future */
      // {
      //   path: 'sandbox-analytics',
      //   title: 'Sandbox Analytics',
      //   icon: 'icon-api-analytics',
      // },
    ],
  },
  {
    title: 'Discover Digital Content',
    path: 'discover-apis',
    items: [],
  },
  {
    title: 'Settings',
    path: 'settings',
    restrictedUsers: [
      RolesEnum.USER,
      RolesEnum.ANONYMOUS_USER,
      RolesEnum.EXTERNAL_USER,
      RolesEnum.INTERNAL_USER,
      RolesEnum.OBIE_USER,
      RolesEnum.THIRD_PARTY_ADMIN,
    ],
    items: [
      {
        path: '/portal-configuration',
        title: 'Portal Configurations',
        icon: 'icon-settings-configuration',
      },
      {
        path: '/ui-customisation',
        title: 'UI Customisation',
        icon: 'icon-settings-customisation',
      },
      {
        path: '/sso-configurations',
        title: 'SSO Configurations',
        icon: 'icon-sso-config',
      },
    ],
  },
  {
    title: 'Profile',
    path: 'profile',
    hideOnHeader: true,
    items: [
      {
        path: 'change-password',
        title: 'Change Password',
        icon: 'icon-reset-password',
      },
      {
        path: 'privacy-setting',
        title: 'Privacy Setting',
        icon: 'icon-reset-password',
      },
    ],
  },
  {
    title: 'Help',
    path: 'help',
    items: [
      {
        path: 'contact-us',
        title: 'Contact Us',
        icon: 'icon-person',
      },
    ],
  },
  {
    title: 'Terms And Privacy',
    path: 'terms-privacy',
    hideOnHeader: true,
    items: [
      {
        path: 'terms-and-conditions',
        title: 'Terms And Conditions',
        icon: 'icon-api-analytics',
      },
      {
        path: 'privacy-policy',
        title: 'Privacy Policy',
        icon: 'icon-api-analytics',
      },
    ],
  },
];
