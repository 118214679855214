/**
 * Function is to generate the darker and lighter shade of color.
 * @param hexColor : As a input user has to (hex color code) and the percentage value they want the color shade.
 * @param magnitude : Can accept negative (for darker shade) and positive (for lighter shade) values both
 * @returns updated hex-color with the required shade
 */
export function generateColorShade(
  hexColor: string,
  magnitude: number,
): string {
  //magnitude can accept negative (for darker shade) and positive (for lighter shade) values both
  hexColor = hexColor.replace('#', '');
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    const rE = decimalColor >> 16;
    const gE = (decimalColor >> 8) & 0x00ff;
    const bE = decimalColor & 0x0000ff;
    // Identify the lighter shade and change to negative magnitude
    // Here luma lies between 0-255, 0 > darkest and 255 > lightest
    // const luma = 0.2126 * rE + 0.7152 * gE + 0.0722 * bE; // per ITU-R BT.709
    // if (luma > 40 && magnitude > 0) {
    //     magnitude = -1 * magnitude;
    // }

    let r = rE + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = gE + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = bE + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(b | (g << 8) | (r << 16)).toString(16)}`;
    // return `rgb(${r}, ${g}, ${b})`; // facing some issue with hash code (using rgb directly)
  }
  return hexColor;
}
