import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';
import { first, map, of, tap } from 'rxjs';

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService: AuthService = inject(AuthService);

  if (typeof window !== 'undefined') {
    return authService.userData.pipe(
      first(),
      map(
        (user) =>
          !!user?.email && !user.email.endsWith('iam.gserviceaccount.com'),
      ),
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          authService.redirectUrl = state.url;
          authService.redirectToLogin();
        }
      }),
    );
  }
  return of(false);
};

export const canActivateIfUnauthenticated: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService: AuthService = inject(AuthService);

  if (typeof window !== 'undefined') {
    return authService.userData.pipe(
      first(),
      map(
        (user) =>
          !user?.email || !!user?.email?.endsWith('iam.gserviceaccount.com'),
      ),
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          authService.redirectUrl = state.url;
          authService.redirectToDashboard();
        }
      }),
    );
  }

  return of(false);
};
