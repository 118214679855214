<div
  data-testid="sidebar"
  class="sidebar d-none d-md-block"
  [class.sidebar-collapse]="isCollapsed"
  *ngIf="
    currentNavList && currentNavList?.items && currentNavList?.items?.length
  "
>
  <a
    data-testid="sidebar-toggle"
    href="javascript:void(0)"
    class="sidebar__toggle"
    (click)="isCollapsed = !isCollapsed"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.377"
      height="13.502"
      viewBox="0 0 7.377 13.502"
    >
      <g id="arrow" transform="translate(6.052 0.66) rotate(90)">
        <path
          id="Path_30664"
          data-name="Path 30664"
          d="M0,5.293,6.021,0,12.19,5.293"
          transform="translate(0 0)"
          fill="none"
          stroke="#ffffff"
          stroke-width="2"
        />
      </g>
    </svg>
  </a>
  <ul
    class="sidebar__list"
    *ngIf="currentNavList"
    data-testid="sidebar-nav-list"
  >
    <ng-container *ngFor="let item of currentNavList.items">
      <li
        [attr.data-testid]="'sidebar-nav-' + item.title"
        class="sidebar__list__item font__semibold"
        [routerLink]="[
          defaultRoute +
            (currentNavList.path === 'home' ? '' : currentNavList.path) +
            '/' +
            item.path
        ]"
        routerLinkActive="active"
      >
        <i class="icomoon" [class]="item.icon"></i>
        <span>
          {{ item.title }}
        </span>
      </li>
    </ng-container>
  </ul>
</div>
