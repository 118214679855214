<nav class="navbar navbar-expand-lg navbar-light bg-light header__bar">
  <div class="container-fluid">
    <div
      class="d-flex py-2 py-md-0 justify-content-between align-items-center py-2 col-12 col-md-12 col-lg-4"
    >
      <button
        data-testid="header-navbar-collapse"
        class="navbar-hamburger border-0 p-0 m-0 bg-transparent collapse__visible fs-5"
        type="button"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="icon-meat"></i>
      </button>
      <a
        class="navbar-brand me-lg-auto me-md-auto"
        data-testid="header-logo"
        [routerLink]="['/']"
      >
        <img
          class="header__bar__logo"
          alt="one-service-hub-logo"
          width="100%"
          [src]="
            logoImage
              ? 'data:image/jpeg;base64,' + logoImage
              : '/assets/images/logo.webp'
          "
        />
      </a>
      <div *ngIf="!!userData" class="header__icon ms-2 d-md-none">
        <span class="header__icon__svg" data-testid="header-navbar-user-icon">
          <i class="icommon icon-person"></i>
        </span>
      </div>
    </div>
    <div
      class="d-flex py-2 py-md-0 ms-md-auto me-md-1 justify-content-md-end justify-content-center align-items-center col-12 col-md-6 col-lg-5 border__top__sm pb-1"
    >
      <ng-container *ngIf="(userPlanDetails$ | async)?.credits">
        <div
          data-testid="header-dropdown-plan-action"
          *ngIf="isAdmin"
          class="d-flex align-items-center px-2 position-relative cursor-pointer border__right"
          dropdown
          dropdownToggle
        >
          <i class="icommon icon-corporate icon__plan i-icon--bw"></i>
          <p class="mb-0 ps-1 d-none d-md-block">
            <span
              class="badge badge__outline badge__outline--header"
              data-testid="header-dropdown-plan-name"
            >
              {{ (userPlanDetails$ | async)?.name }}</span
            >
          </p>
          <i class="icommon icon-arrow-right icon__down text__body"></i>
          <div
            data-testid="header-dropdown-plan-content"
            id="dropdown-plan"
            *dropdownMenu
            class="i-dropdown-menu dropdown-menu dropdown-menu-right credits-dropdown"
            role="menu"
            aria-labelledby="button-plan"
            placement="left"
          >
            <span
              class="badge badge__outline d-block d-md-none mx-1"
              data-testid="header-dropdown-plan-content-name"
            >
              {{ (userPlanDetails$ | async)?.name }}</span
            >
            <h6 class="px-2 py-2">Credits</h6>
            <div
              class="credits-dropdown__list"
              *ngFor="let credit of (userPlanDetails$ | async)?.credits"
            >
              <div>
                <h6
                  class="mb-1"
                  [attr.data-testid]="
                    'header-dropdown-credit-name-' + credit.name
                  "
                >
                  {{ credit.name }}
                </h6>
                <span
                  [attr.data-testid]="
                    'header-dropdown-credit-frequency-' + credit.name
                  "
                  class="badge badge__outline"
                  >{{ credit.frequency }}</span
                >
                <div class="d-flex align-items-center">
                  <progressbar
                    [attr.data-testid]="
                      'header-dropdown-credit-progress-' + credit.name
                    "
                    class="i-progress credits-dropdown__progress"
                    [value]="getProgressVal(credit)"
                  ></progressbar>
                  <span
                    class="ms-2 credits-dropdown__points"
                    [attr.data-testid]="
                      'header-dropdown-credit-remaining-' + credit.name
                    "
                    >{{ credit.remainingCredit?.remainingCredits }}/{{
                      credit.quantity
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center px-2">
          <i class="icommon icon-business-and-trade icon__org i-icon--bw"></i>
          <p class="mb-0 ps-1">
            <span
              class="badge badge__outline badge__outline--header"
              data-testid="header-organisation-name"
            >
              {{ userCompactDetails?.userOrganisation?.organisationName }}</span
            >
          </p>
        </div>
      </ng-container>

      <div class="d-md-none ms-auto">
        <ng-container [ngTemplateOutlet]="versionElement"></ng-container>
      </div>
    </div>
    <ng-container *ngIf="!userData">
      <button
        data-testid="header-signup-btn"
        type="button"
        class="btn btn-outline-primary me-3"
        [routerLink]="['/auth/signup']"
      >
        Sign Up
      </button>
      <button
        data-testid="header-login-btn"
        type="button"
        class="btn btn-primary ibtn ibtn__primary"
        [routerLink]="['/auth']"
      >
        Login
      </button>
    </ng-container>
    <div
      class="d-flex header__user btn-group position-relative cursor-pointer"
      dropdown
      *ngIf="userCompactDetails?.userModel && !!userData"
      dropdownToggle
      data-testid="header-dropdown-user-toggle"
    >
      <div class="header__icon ms-2">
        <span class="header__icon__svg">
          <i class="icommon icon-person"></i>
        </span>
      </div>
      <div
        class="ms-3 d-flex flex-column justify-content-center"
        data-testid="header-dropdown-label-user-name"
      >
        <p class="header__user__hello header__text font__semibold mb-0">Hi</p>
        <div class="header__user__label text__muted font__regular">
          {{
            userCompactDetails?.userModel?.firstName
              ? userCompactDetails?.userModel?.firstName +
                ' ' +
                userCompactDetails?.userModel?.lastName
              : userData.email
          }}
        </div>
      </div>
      <ul
        id="dropdown-basic"
        *dropdownMenu
        class="i-dropdown-menu dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-basic"
        data-testid="header-dropdown-user-menu"
      >
        <li
          role="menuitem"
          *ngIf="userCompactDetails?.userModel?.firstName"
          disabled
        >
          <div class="dropdown-item" disabled>
            <div class="font__semibold" data-testid="header-dropdown-user-name">
              {{
                userCompactDetails?.userModel?.firstName +
                  ' ' +
                  userCompactDetails?.userModel?.lastName
              }}
            </div>
            <div class="fs__14" data-testid="header-dropdown-user-email">
              {{ userData.email }}
            </div>

            <div class="d-flex flex-wrap">
              <div
                class="badge badge__outline mt-1 me-2 mb-2"
                *ngFor="let role of userRoles"
                [attr.data-testid]="'header-dropdown-user-role-' + role"
              >
                {{ role }}
              </div>
            </div>
          </div>
        </li>
        <!-- Profile -->
        <li>
          <hr class="dropdown-divider" />
        </li>
        <li role="menuitem">
          <a
            data-testid="header-dropdown-user-profile-link"
            class="dropdown-item"
            href="javascript:void(0)"
            [routerLink]="['/home/profile']"
            >Profile</a
          >
        </li>
        <!-- Logout -->
        <li>
          <hr class="dropdown-divider" />
        </li>
        <li role="menuitem">
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            data-testid="header-dropdown-user-profile-logout"
            (click)="logout()"
            >Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
<div
  class="navigation-menu d-flex justify-content-start w-100"
  [class.show]="!isCollapsed"
>
  <!-- bg__primary__dark -->
  <div
    *ngIf="userData"
    class="primary d-flex flex-column sub__header px-3 ps-0 pe-5 py-3 py-md-0 pe-md-0 text-white container-md"
  >
    <button
      data-testid="header-collapse-toggle"
      class="navbar-hamburger border-0 px-2 py-0 m-0 bg-transparent collapse__visible fs-5 text-start"
      type="button"
      (click)="isCollapsed = !isCollapsed"
    >
      <i class="icon-meat text-white"></i>
    </button>
    <ul class="nav text-white" data-testid="header-navbar">
      <ng-container *ngFor="let nav of navList">
        <ng-container *ngIf="!nav.hideOnHeader">
          <li
            class="nav-item"
            *ngIf="
              !nav?.restrictedUsers ||
              (userHighestRole &&
                !nav?.restrictedUsers?.includes(userHighestRole))
            "
          >
            <a
              [attr.data-testid]="'header-navbar-link-' + nav.title"
              [routerLink]="[getRouterPath(nav)]"
              [class.active]="customRouteActive(nav.path)"
              class="nav-link link-light px-2"
              (click)="
                currentNavList && currentNavList.items.length! > 1
                  ? (isCollapsed = !isCollapsed)
                  : null
              "
            >
              {{ nav.title }}
            </a>
          </li>
        </ng-container>
      </ng-container>
      <li class="nav-item collapse__visible">
        <a
          data-testid="header-navbar-profile"
          href="javascript:void(0)"
          class="nav-link link-light px-2"
          [routerLink]="['/home/profile']"
          (click)="isCollapsed = !isCollapsed"
          >Profile</a
        >
      </li>
      <li class="nav-item collapse__visible">
        <a
          data-testid="header-navbar-logout"
          href="javascript:void(0)"
          class="nav-link link-light px-2"
          (click)="logout()"
          >Logout</a
        >
      </li>

      <div class="d-none d-md-block ms-auto">
        <ng-container [ngTemplateOutlet]="versionElement"></ng-container>
      </div>
    </ul>
  </div>
  <div
    *ngIf="currentNavList && currentNavList.items.length > 0"
    class="d-flex sub__header d-md-none p-2 pt-5 py-md-2"
  >
    <ul class="nav p-0">
      <ng-container *ngFor="let item of currentNavList.items">
        <li class="nav-item">
          <a
            [attr.data-testid]="'header-navbar-link-' + item.title"
            class="nav-link px-2"
            [routerLink]="[
              defaultRoute +
                (currentNavList.path === 'home' ? '' : currentNavList.path) +
                '/' +
                item.path
            ]"
            routerLinkActive="active"
            (click)="isCollapsed = !isCollapsed"
          >
            <i class="icomoon" [class]="item.icon"></i>
            <span>
              {{ item.title }}
            </span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<!-- version/environment element -->
<ng-template #versionElement>
  <div class="ms-auto version h-100" *ngIf="version">
    <span
      class="badge badge__outline badge__outline--navbar"
      data-testid="header-navbar-version"
    >
      Version: {{ getEnvironmentName ? getEnvironmentName + '-' : ''
      }}{{ version }}
    </span>
  </div>
</ng-template>

<!-- chat bot -->
<app-chat-bot
  data-testid="chat-bot"
  *ngIf="userCompactDetails?.userModel?.email && userRoles"
  [userEmail]="userCompactDetails?.userModel?.email ?? ''"
  [userRoles]="userRoles"
>
</app-chat-bot>
