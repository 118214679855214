import { NgModule } from '@angular/core';
import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config';
import { getApp } from '@angular/fire/app';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { provideAuth } from '@angular/fire/auth';

import {
  initializeAuth,
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
} from '@angular/fire/auth';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { provideHttpClient } from '@angular/common/http';

@NgModule({
  imports: [
    AppModule,
    provideRemoteConfig(() => getRemoteConfig()),
    provideAuth(() => {
      const auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver,
      });
      return auth;
    }),
  ],
  providers: [provideHttpClient(), provideOAuthClient()],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
