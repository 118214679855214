export enum RolesEnum {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
  INTERNAL_USER = 'INTERNAL_USER',
  EXTERNAL_USER = 'EXTERNAL_USER',
  OBIE_USER = 'OBIE_USER',
  ANONYMOUS_USER = 'ANONYMOUS_USER',
  THIRD_PARTY_ADMIN = 'THIRD_PARTY_ADMIN',
}
