import { Injectable, OnDestroy, Optional } from '@angular/core';
import {
  Auth,
  User,
  authState,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Observable, Subscription, catchError, from, throwError } from 'rxjs';
import { CONSTANTS } from '../../constants/constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  redirectUrl = '' as string;
  accessToken = '' as string;
  // userDetail = {} as UserInfo;
  userDetail = {} as User;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private tokenRefreshTimer: any; // Timer for token refresh

  public userData!: Observable<User | null>;
  private readonly userDisposable: Subscription | undefined;

  constructor(
    private router: Router,
    @Optional() private auth: Auth,
    private toastService: ToastService,
  ) {
    if (this.auth) {
      this.userData = authState(this.auth);
      this.userDisposable = authState(this.auth).subscribe((user) => {
        if (user && user.email) {
          user.getIdToken().then((token) => {
            this.setToken(token);
            this.userDetail = user;
            this.scheduleTokenRefresh(); // Schedule token refresh when user signs in
          });
        }
      });
    }
  }

  private scheduleTokenRefresh() {
    this.clearTokenRefreshTimer();

    /** Clear existing timer */
    if (this.userDetail) {
      /** Get the token expiration time and schedule refresh */
      this.userDetail
        ?.getIdTokenResult()
        .then((idTokenResult) => {
          const expirationTimeMs = new Date(
            idTokenResult.expirationTime,
          ).getTime(); // Expiration time in milliseconds
          const refreshTimeMs = expirationTimeMs - Date.now() - 300000; // Refresh 5 minutes before expiration
          this.tokenRefreshTimer = setTimeout(() => {
            this.refreshToken();
          }, refreshTimeMs);
        })
        .catch(() => {
          /** if got error, generate the new token */
          this.refreshToken();
        });
    }
  }

  private clearTokenRefreshTimer() {
    if (this.tokenRefreshTimer) {
      clearTimeout(this.tokenRefreshTimer);
    }
  }

  private refreshToken() {
    this.userDetail
      ?.getIdToken(true)
      .then((token) => {
        this.setToken(token);
        this.scheduleTokenRefresh(); // Schedule next refresh
      })
      .catch((error) => {
        console.error('Error refreshing token:', error);
      });
  }

  ngOnDestroy(): void {
    if (this.userDisposable) {
      this.userDisposable.unsubscribe();
    }
    this.clearTokenRefreshTimer();
  }

  getAccessToken() {
    return this.accessToken;
  }

  getUserDetail() {
    return this.userDetail;
  }

  redirectToLogin() {
    this.router.navigate(['/auth']);
  }

  redirectToDashboard() {
    this.router.navigate(['/home']);
  }

  signInWithCustomToken(customToken: string) {
    return from(signInWithCustomToken(this.auth, customToken)).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  signUp(email: string, password: string) {
    return (
      createUserWithEmailAndPassword(this.auth, email, password)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.user && res?.user.accessToken) {
            this.setToken(res?.user.accessToken);
          }
          return res;
        })
        .catch((error) => {
          this.handlerAuthErrors(error.code, error.message);
          return throwError(() => error);
        })
    );
  }

  signIn(email: string, password: string) {
    return (
      signInWithEmailAndPassword(this.auth, email, password)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.user && res?.user.accessToken) {
            this.setToken(res?.user.accessToken);
          }
          return res;
        })
        .catch((error) => {
          this.handlerAuthErrors(error.code, error.message);
        })
    );
  }

  signOut(path = '') {
    signOut(this.auth)
      .then(() => {
        localStorage.clear();
        this.router.navigate([path]);
      })
      .catch((error) => {
        this.handlerAuthErrors(error.code, error.message);
      });
  }

  // TO DO: Need to work on the functionality in use
  sendPasswordResetEmail(email: string) {
    return (
      sendPasswordResetEmail(this.auth, email)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          return res;
        })
        .catch((error) => {
          this.handlerAuthErrors(error.code, error.message);
        })
    );
  }

  confirmPasswordReset(code: string, newPassword: string) {
    return confirmPasswordReset(this.auth, code, newPassword);
  }

  changePassword(newPassword: string) {
    if (this.auth.currentUser) {
      const currentUser = this.auth?.currentUser;
      return (
        updatePassword(currentUser, newPassword)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((res: any) => {
            return res;
          })
          .catch((error) => {
            this.handlerAuthErrors(error.code, error.message);
          })
      );
    }
    return new Promise<void>((resolve, reject) => {
      reject();
    });
  }

  // handle all the auth error here
  handlerAuthErrors(errorCode: string, errorMessage: string) {
    const messages = CONSTANTS.MESSAGES.AUTH as { [key: string]: string };
    const message = (messages[errorCode] as string) ?? errorMessage;
    if (message) this.toastService.error(message);
  }

  handlerFirebaseErrors(errorCode: string, errorMessage: string) {
    const messages = CONSTANTS.MESSAGES.AUTH as { [key: string]: string };
    const message = (messages[errorCode] as string) ?? errorMessage;
    return message;
  }

  // get email from local storage
  setEmailInLocalStorage(value: string) {
    localStorage.setItem('email', btoa(value));
  }

  get getEmailInLocalStorage() {
    if (typeof localStorage !== 'undefined') {
      const getLocalValue = localStorage.getItem('email');
      return getLocalValue ? atob(getLocalValue) : '';
    }
    return '';
  }

  setToken(token: string) {
    this.accessToken = token;
    localStorage.setItem('token', token);
  }
}
